
  
@font-face {
  font-family: 'Montserrat Black';
  src: url('./assets/fonts/Montserrat-Black.ttf');
  
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('./assets/fonts/Montserrat-Bold.ttf');
}

.version-info {
  font-size: 8pt;
  float: right;
  margin: 8px;
}


 .hide-checkbox .mat-pseudo-checkbox { display: none; }
 
 .dialog-container-custom .mat-dialog-container {
	width: 36.5rem;
}

.ngx-pagination {
  padding-left: 0px !important;
}

.ngx-pagination .current,
.btn-info {
  background: #494949 !important;
  border: transparent !important;
}

.ngx-pagination .current:hover {
  background: #494949 !important;
  border: transparent !important;
}


.mat-datepicker-toggle-active {
  color: #ee1f99!important;
}


.mat-form-field {
    width: 100%;
  }



::ng-deep .mat-option .mat-pseudo-checkbox{ display: none; }
  .mat-elevation-z1 {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.01),0px 1px 1px 0px rgba(0, 0, 0, 0.03),0px 1px 3px 0px rgba(100, 100, 100, 0.3)!important;
  }

  .mat-fab:not([class*="mat-elevation-z"]), .mat-mini-fab:not([class*="mat-elevation-z"]) {
    box-shadow: none!important;
  }

  .chart-legend .legend-labels{
    background: none!important;
  }

  .mat-drawer-content {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow: auto;
    overflow-y: auto!important;
  }
 
 

  .mat-tab-body-content {
    overflow:hidden!important;
  }

  // .mat-form-field-wrapper {
  //   padding-bottom: 1.24375em!important;
  // }

  .mat-form-field-underline {
    color: #c2c7cc!important;
    bottom: 1.4375em!important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: #c2c7cc!important;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-color: #c2c7cc!important;
    background-image: none!important;
  }

//modification padding textarea
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: .5em 0!important;
}

// apparance du border textarea
.mat-form-field-appearance-outline .mat-form-field-outline {
  //color: #ee1f99 !important;
  color: #c2c7cc !important;
  opacity: 1;
}

// ligne boder color
.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0.5px !important;
  color: #ee1f99 !important;
  background-color:#c2c7cc !important;
}

// mat form apparence line color influencelab quand on clique sur la line
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline, .mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #ee1f99 !important;
}

// mat form button color influencelab
.mat-form-field-ripple {
  background-color: #ee1f99 !important;
}
  
  // select option disabeld
  .mat-select-disabled  .mat-select-value {
    color: #565656!important;
    // opacity: 0.65!important;
  }


  // mat-select option liste 
  .mat-select-value-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #565656!important;
  }

   // ligne pour les formulaire en disabled campagne et offer details form line
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(#c2c7cc) !important; 
    //background: #eee!important;
  }


  // ligne pour les formulaire en disabled camapgne et offer details form text area
  .mat-form-field-appearance-legacy.mat-form-field-underline {
    background-image: linear-gradient(#c2c7cc) !important; 
    //background: #eee!important;
  }


  // textarea focus avec clique
  .mat-focused .mat-form-field-label {
    color: #ee1f99 !important;
    font-size: 14px!important;
  } 
  
  // check box angular form
  .mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #ee1f99 !important;
  }
  
  // options angular material option
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #ee1f99 !important;
  }
  

  //espaces entre les icons angular material
  .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    width: 1.9em !important;
    margin-bottom: 0.49em!important;
    
  }

  .mat-expansion-panel-body {
    padding: 0 12px 8px!important;
  }





  .mat-card {
    background: white;
    width: 100%;
    color: #565656 !important;
    font-family: "Open Sans",Helvetica,Arial,sans-serif!important;
    font-size: 14px!important ;
    line-height: 1.3!important;
  }

  .input-row{
    padding: 0.15rem;
  }
    
  .input-row-selector{
    padding: 0.75rem;
  }


  .paddingButton{
    padding-bottom: 1.34375em;
  }

  .mat-tab-label { 
    // opacity: 0.75!important;
    color: #565656!important;
    font-weight: 700!important;
    font-family: "Open Sans",Helvetica,Arial,sans-serif!important;
    font-size: 14px!important ;
    line-height: 1.3!important;
  }
