/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/fonts/font-awesome/font-awesome.css";


html { height: 100%; }

body {
  height: 100%;
}

[flex-center] {
    display:flex;
    justify-content: center;
  }

  [text-center] {
    text-align: center;
  }
  